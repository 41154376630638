import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const MerchPage = () => {
	return (
		<Layout>
			<Seo title="Now" />
			<h2>Now</h2>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/photos/pressRes/Jesse_Rivest_Toadstool_007.jpg"
				alt="Jesse Rivest press image"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={500}
				/>
			</div>

			<h3>What am I doing now?</h3>
			<p>
				Updated March 31, 2025.<br />
				(This is a <span className="italic">now page</span> in the style of Derek Sivers.)
			</p>
			<h4>Session recording/arranging</h4>
			<p>
				I spent all of last year trying for a job in software engineering.
				I got a total of two responses (neither chose to hire me),
				and that's with 12+ years experience and a total of 20+ years IT experience.
				I have always needed a job outside of music.
				It's not just about money to live, it's also about money for equipment, maintenance, investing in my music, and much more.
				I was broke all last year, and I still am.
				I am borrowing a recording interface from a friend because I can't afford to replace my self-retired one.
			</p>
			<p>
				A music friend back home in Kelowna recommended I try getting some work on AirGigs.
				I enlisted myself and set my prices low in hope of incentivizing some activity and building reviews.
				So far, that's been working: I've been recording guitars, arranging background vocals for songs, and also mixing/mastering songs.
				It's been satisfying—working with and getting paid for music—but I am not earning enough to even cover food.
				Now that I'm getting some reviews under my belt, I will analyze the platform more and soon reset my prices.
				I have to choose prices that are a compromise: fair for me,
				but also relative and reflective of where I and my studio are at in my/our musical journey.
			</p>
			<h4>Live-streaming</h4>
			<p>
				Last year I was live-streaming weekly: I would practice a set of songs and then put on a performance...
				to essentially nobody.
				(Taps the mic, "Is this thing on?" Microphonic feedback whines.)
				So, by the end of year I had already pivoted to what I started this year doing:
				I am taking a set of songs and practicing them during a livestream.
				I still gain the odd new subscriber (I do this on YouTube) and I am also eroding the pressure I put on myself for performances in general.
				Viewers get to see what I do to work through problem parts of songs and also get the odd solid performance of a song.
				There's also some chatting or joking around when a viewer gets into the chat.
				</p>
			<h4>Prioritizing (and adjusting)</h4>
			<p>
				I started the year with a clear and considered set of priorities.
				The new AirGigs work I'm doing is the result of one of the priorities, which was:
				Get my services up on some online platforms.
				Two other top priorities have been taking much longer, especially now that AirGigs is taking up some of my time:
				finish a note-organizing (software) app I started,
				and finish my audio/chart guides for 10 – 15 songs for shows with a trio.
				I'm so discouraged with the state of software engineering that I have let the prior drop now for nearly two months.
				The latter, though, I am trying to put regular time into.
				There's a third priority—more of an ongoing one—which is to revisit a batch of songs I wrote back in 2020/21/22.
				They have a lot of potential but require me to embody them—put them on and wear them—which usually results in edits.
				Especially, lyrical edits come up, as do edits to how I might perform (guitar) them alone or with a trio.
				This revisiting of these songs will ultimately lead to a new recording of some length—EP or LP.
				The going is slow, for now.
			</p>

		</Layout>
	);
}

export default MerchPage;
